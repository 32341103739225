import Div from "@hellodarwin/core/lib/components/common/div";
import { ModalConfirm } from "@hellodarwin/core/lib/components/common/hd-modal/confirm";
import ProjectActionsButton from "@hellodarwin/core/lib/components/project/project-actions-button";
import { Match } from "@hellodarwin/core/lib/features/entities";
import {
  Action,
  PrioritizedProjectActions,
} from "@hellodarwin/core/lib/features/enums/action-entities";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";
import getShortId from "@hellodarwin/core/lib/features/helpers/get-short-id";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import { toggleModal } from "../../../features/api/slices/global-slice";
import {
  archiveProject,
  declareSelfWinner,
  setSelectedMatchId,
  unIgnoreProject,
  unarchiveProject,
} from "../../../features/api/slices/matches-slice";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import { usePartnerApi } from "../../../features/api/use-partner-api";
import showErrorNotification from "../../../features/helpers/show-error-notifications";

type ProjectActionsProps = {
  match: Match;
};

const ProjectActions = ({ match }: ProjectActionsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const navigate = useNavigate();
  const profile = useAppSelector(selectProfile);
  const projectType = match.project.type;
  const matchId = match.match_id;
  const raiseHand = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedMatchId(matchId));
    dispatch(toggleModal({ isVisible: true, type: "raiseHandPurchase" }));
  };

  const purchaseLead = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(setSelectedMatchId(matchId));
    dispatch(toggleModal({ isVisible: true, type: "leadPurchase" }));
  };

  const ignoreProject = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedMatchId(matchId));
    dispatch(toggleModal({ isVisible: true, type: "ignoreProject" }));
  };

  const archiveMatch = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await dispatch(archiveProject({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    } finally {
      navigate("/projects/ongoing");
    }
  };

  const unarchiveMatch = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await dispatch(unarchiveProject({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    } finally {
      navigate("/projects/ongoing");
    }
  };

  const unIgnoreMatch = async (e: SyntheticEvent) => {
    e.stopPropagation();
    try {
      await dispatch(unIgnoreProject({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const fetchInvoice = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const invoice = await api.fetchInvoice(matchId);
      window.open(invoice.HostedURL, "_blank");
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const fetchRhInvoice = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const invoice = await api.fetchRaisehandInvoice(matchId);
      window.open(invoice.HostedURL, "_blank");
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const fetchWinFeeInvoice = async (e?: SyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    try {
      const invoice = await api.fetchWinFeeInvoice(matchId);
      window.open(invoice.HostedURL, "_blank");
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const handleCreditRequest = () => {
    window.location.href = t("project|creditEmailTemplate", {
      project_id: getShortId(match.project_id),
      company_name: profile.name,
    });
  };

  const declareWinner = async (e?: SyntheticEvent) => {
    ModalConfirm({
      title: t("project|projectActions|declareWinnerConfirmationTitle"),
      content: t("project|projectActions|declareWinnerConfirmationContent"),
      okText: t("button.confirm"),
      cancelText: t("button.cancel"),
      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: "24px",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: "24px",
        },
      },
      onOk: async () => {
        declareWinnerConfirmed(e);
      },
      width: 600,
    });
  };

  const declareWinnerConfirmed = async (e?: SyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    try {
      await dispatch(declareSelfWinner({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    } finally {
      navigate("/projects/closed");
    }
  };

  const [actions, setActions] = useState<ReactNode>(<></>);

  const viewInvoiceAction: Action = {
    label: t("project|projectActions|viewInvoice"),
    action: fetchInvoice,
  };

  const viewRhInvoiceAction: Action = {
    label: t("project|projectActions|viewRhInvoice"),
    action: fetchRhInvoice,
  };

  const viewWinFeeInvoiceAction: Action = {
    label: t("project|projectActions|viewWinFeeInvoice"),
    action: fetchWinFeeInvoice,
  };

  const creditRequestAction: Action = {
    label: t("project|projectActions|creditRequest"),
    action: handleCreditRequest,
  };

  const archiveAction: Action = {
    label: t("project|projectActions|archive"),
    action: archiveMatch,
  };

  const unarchiveAction: Action = {
    label: t("project|projectActions|unarchive"),
    action: unarchiveMatch,
  };

  const raiseHandAction: Action = {
    label: t("project|projectActions|raiseHand"),
    action: raiseHand,
  };

  const declareWinnerAction: Action = {
    label: t("project|projectActions|declareWinner"),
    action: declareWinner,
  };

  const ignoredProjectActions: PrioritizedProjectActions = {
    primaryAction: [
      {
        label: t("project|projectActions|removeFromIgnore"),
        action: unIgnoreMatch,
      },
    ],
    secondaryActions: [],
  };

  const newClassicProjectActions: PrioritizedProjectActions = {
    primaryAction: [
      {
        label: t("project|projectActions|buy"),
        action: purchaseLead,
      },
    ],
    secondaryActions: [
      { label: t("project|projectActions|ignore"), action: ignoreProject },
    ],
  };
  const newRhProjectActions: PrioritizedProjectActions = {
    primaryAction: [raiseHandAction],
    secondaryActions: [
      { label: t("project|projectActions|ignore"), action: ignoreProject },
    ],
  };
  const rejectedProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [archiveAction],
  };
  const rejectedRhProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewRhInvoiceAction],
    secondaryActions: [archiveAction],
  };
  const archivedProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [creditRequestAction, unarchiveAction],
  };
  const archivedRhProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewRhInvoiceAction],
    secondaryActions: [creditRequestAction, unarchiveAction],
  };
  const shortlistProjectActions: PrioritizedProjectActions = {
    primaryAction: [declareWinnerAction],
    secondaryActions: [viewInvoiceAction, viewRhInvoiceAction, archiveAction],
  };
  const shortlistForeverProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [viewRhInvoiceAction, archiveAction],
  };
  const winnerProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [archiveAction],
  };
  const rhWinnerProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [viewRhInvoiceAction, archiveAction],
  };

  if (match.win_fee_invoice_id !== "") {
    rhWinnerProjectActions.secondaryActions?.push(viewWinFeeInvoiceAction);
  }

  const defaultProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [creditRequestAction, archiveAction],
  };
  const defaultRhProjectActions: PrioritizedProjectActions = {
    primaryAction: [viewRhInvoiceAction],
    secondaryActions: [creditRequestAction, archiveAction],
  };

  useEffect(() => {
    switch (match.status) {
      case "Ignored":
        setActions(<ProjectActionsButton actions={ignoredProjectActions} />);
        break;
      case "New":
        if (projectType === ProjectType.Classic) {
          setActions(
            <ProjectActionsButton actions={newClassicProjectActions} />
          );
          break;
        }
        setActions(<ProjectActionsButton actions={newRhProjectActions} />);
        break;
      case "Waiting":
      case "Rejected":
        if (projectType === ProjectType.Classic) {
          setActions(<ProjectActionsButton actions={rejectedProjectActions} />);
          break;
        }
        setActions(<ProjectActionsButton actions={rejectedRhProjectActions} />);
        break;
      case "Archive":
        if (projectType === ProjectType.Classic) {
          setActions(<ProjectActionsButton actions={archivedProjectActions} />);
          break;
        }
        setActions(<ProjectActionsButton actions={archivedRhProjectActions} />);
        break;
      case "Bought":
      case "Winner":
        if (projectType === ProjectType.Classic) {
          setActions(<ProjectActionsButton actions={winnerProjectActions} />);
          break;
        }
        setActions(<ProjectActionsButton actions={rhWinnerProjectActions} />);
        break;
      case "Shortlisted":
        setActions(<ProjectActionsButton actions={shortlistProjectActions} />);
        break;
      case "Finalist":
        setActions(
          <ProjectActionsButton actions={shortlistForeverProjectActions} />
        );
        break;
      default:
        if (projectType === ProjectType.Classic) {
          setActions(<ProjectActionsButton actions={defaultProjectActions} />);
          break;
        }
        setActions(<ProjectActionsButton actions={defaultRhProjectActions} />);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.status, t]);

  return (
    <Div flex={"row"} justify={"flex-end"} gap={8} align="center">
      <Div flex="row" gap={8} fitContent align="center">
        {actions}
      </Div>
    </Div>
  );
};

export default ProjectActions;

